<template>
  <div>
    <currentLocation :sibItem="sibItem"></currentLocation>
    <div class="outer">
      <div class="cont">
        <div class="title">{{ detail.title }}</div>
        <div v-html="detail.content"></div>
      </div>
    </div>
  </div>
</template>
<script>
/**
 * Created by www.xingnuocn.com
 * @title：技术研发-科研成果-期刊详情
 *
 * @author: 刘嘉鑫
 * @version  V1.0
 * @datecreate: 2022-08-25 14:28
 */
export default {
  data() {
    return {
      id: "",
      detail: "",
    };
  },
  mounted() {
    this.id = this.$route.query.id;
    this.journalDetails();
  },
  methods: {
    /**
     * 详情
     * 刘嘉鑫
     * 2022-8-25
     */
    journalDetails() {
      this.$request({
        url: "/Index/patentInfo",
        data: {
          id: this.id,
        },
      }).then((res) => {
        console.log("详情", res);
        this.detail = res.patent_info.replace(
          new RegExp("<img", "g"),
           "<img style='max-width:100%;height:auto;margin:0 auto;'"
        );
      });
    },
  },
};
</script>
<style lang='scss' scoped>
.outer {
  background: #f6f6f6;
  padding: 78px 310px 120px;
  display: flex;
  align-items: flex-start;
}

.cont {
  background: #ffffff;
  padding: 58px;
  flex: 1;

  .title {
    font-size: 28px;
    font-family: Source Han Sans CN;
    font-weight: bold;
    color: #222222;
    margin-bottom: 60px;
  }
}
</style>